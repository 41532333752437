<template id="Post">
  <!-- vue实例外创建 -->
  <div>
    <div class="postContainer">
      <div class="searchPart">
        <el-button type="primary" class="addPostButton" @click="addNew" v-if='data.roleId !== 2'>
          <i class="el-icon-plus addIcon"> </i>创建帖子</el-button>
        <div class='roleId' v-if="data.roleId === 1">
          <input id="check" type="checkbox" />
          <label for="check" class="check-in">收起<i class="el-icon-arrow-up"></i></label>
          <label for="check" class="check-out">展开<i class="el-icon-arrow-down"></i></label>
          <div class="formPart element">
            <el-form
              ref="searchFrom"
              :rules="rules"
              label-position="top"
              label-width="80px"
              class="postForm"
              :model="data.searchInfo"
            >
              <el-form-item label="帖子ID" prop="postId">
                <el-input type="postId" placeholder="请输入" v-model.number="data.searchInfo.postId"></el-input>
              </el-form-item>
              <el-form-item label="发表者昵称" prop="createNickName">
                <el-input placeholder="请输入" v-model="data.searchInfo.createNickName"></el-input>
              </el-form-item>
              <el-form-item label="发表者QQ" prop="creatorId1">
                <el-input type="creatorId" placeholder="请输入" v-model.number="data.searchInfo.creatorId1"></el-input>
              </el-form-item>
              <el-form-item label="发表者微信" prop="creatorId2">
                <el-input placeholder="请输入" v-model="data.searchInfo.creatorId2"></el-input>
              </el-form-item>
              <el-form-item label="所属社区" prop="communityName">
                <el-input placeholder="请输入" v-model="data.searchInfo.communityName"></el-input>
              </el-form-item>
              <el-form-item label="帖子类型" prop="type">
                <el-select placeholder="请选择" v-model="data.searchInfo.type">
                  <el-option
                    v-for="(item, index) in data.selectOption2"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发表时间">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="getTime"
                  v-model="data.searchInfo.sendDate"
                  prop="sendDate"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="帖子标签" prop="tagId">
                <el-select placeholder="请选择" v-model="data.searchInfo.tagId">
                  <el-option
                    v-for="(item, index) in data.selectOption5"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主贴ID" prop="parentPostId">
                <el-input type="parentPostId" placeholder="请输入" v-model.number="data.searchInfo.parentPostId">
                </el-input>
              </el-form-item>
              <el-form-item label="审核状态" prop="postStatus">
                <el-select placeholder="请选择" v-model="data.searchInfo.postStatus" @change="getStatus">
                  <el-option
                    v-for="(item, index) in data.selectOption3"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item> </el-form-item>
              <el-form-item>
                <el-button type="primary" class="check" @click="handleSearch">检&nbsp;索</el-button>
                <el-button class="cancel" @click="clearSearchInfoFn">重&nbsp;置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="tablePart">
        <div class="tableBox">
          <el-table
            row-class-name="replyTable-row"
            :data="data.tableList"
            style="width: 100%"
            v-loading="data.loading"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="id" label="帖子ID" align="center"> </el-table-column>
            <el-table-column prop="communityName" label="所属社区" align="center">
              <template v-slot="scope">
                <div>
                  {{ scope.row.communityName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="帖子标题" align="left" min-width="150">
              <template v-slot="scope">
                <div style="cursor: pointer" @click="openView(scope.row)">
                  <span v-if="scope.row.parentPostId" style="color: #f56c6c">[回帖]</span>
                  <span v-if="scope.row.parentPostId" style="color: #4d74ff; text-align: left">{{
                   HtmlFilter(scope.row.desc)
                  }}</span>
                  <span v-else style="color: #4d74ff; text-align: left">{{ scope.row.title }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="creatorNickName" label="发表者" align="center" min-width="150">
              <template v-slot="scope">
                <div class="imgNameBox">
                  <div class="img">
                    <img class="table-td-thumb" :src="scope.row.creatorIcon" alt="缩略图" />
                  </div>
                  <span @click="openUserInfo(scope.row)" style="cursor: pointer; color: #4d74ff">{{
                    scope.row.creatorNickName
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="发表时间" align="center">
              <template v-slot="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <el-table-column prop="pv" label="浏览PV" align="center"> </el-table-column>
            <el-table-column prop="replyNum" label="回帖数" align="center"> </el-table-column>
            <el-table-column prop="likeNum" label="点赞数" align="center"> </el-table-column>
            <el-table-column label="内容标签" align="center">
              <template v-slot="scope">
                {{ changeTag(scope.row.tagId) }}
              </template>
            </el-table-column>
            <el-table-column label="审核状态" align="center">
              <template v-slot="scope">
                {{ changeStaus(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" align="center" min-width="100">
              <template v-slot="scope" style="position: relative">
                <div v-if='scope.row.status !== 5'>
                <el-button
                  style="display: block; margin-left: 0; padding: 0; min-height: 24px"
                  type="text"
                  @click="handleEdit(scope.row)"
                  v-if="data.roleId === 1 || data.roleId === 2"
                  >编辑</el-button
                >
                <div v-if="data.roleId === 1 || data.roleId === 2">
                  <el-button
                    style="display: block; margin-left: 0; padding: 0; min-height: 24px"
                    type="text"
                    :class="[scope.row.isGood == 0 ? '' : 'noHot']"
                    @click="handleHot(scope.$index, scope.row)"
                    v-if="scope.row.parentPostId"
                    >{{ scope.row.isGood == 0 ? '热门' : '取消热门' }}</el-button
                  >
                </div>
                <div v-if="data.roleId === 1 || data.roleId === 2">
                  <el-button
                    style="display: block; margin-left: 0; padding: 0; min-height: 24px"
                    type="text"
                    :class="[scope.row.isGood == 0 ? '' : 'noHot']"
                    @click="handleHot(scope.$index, scope.row)"
                    v-if="!scope.row.parentPostId"
                    >{{ scope.row.isGood == 0 ? '精华' : '取消精华' }}</el-button
                  >
                </div>
                <el-button
                  style="display: block; margin-left: 0; padding: 0; min-height: 24px"
                  type="text"
                  :class="[scope.row.isTop == 0 ? '' : 'noHot']"
                  @click="handleTop(scope.$index, scope.row)"
                  v-if="!scope.row.parentPostId"
                  >{{ scope.row.isTop == 0 ? '置顶' : '取消置顶' }}</el-button
                >

                <el-button
                  style="
                    display: block;
                    margin: 0;
                    padding: 0;
                    min-height: 24px;
                    position: absolute;
                    top: 12px;
                    right: 30px;
                    color: red;
                  "
                  type="text"
                  @click="deleteRow(scope.$index, scope.row)"
                  v-if="data.roleId === 1 || data.roleId === 2"
                  >删除</el-button
                >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="reply-pagination">
          <el-pagination
            style="float: right; padding-top: 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="data.pageInfo.pageNum"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="data.pageInfo.pageSize"
            layout="sizes, prev, pager, next"
            :total="data.tableTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div class="dialogPart">
        <div class='editor' v-if='userInfo.userInfo'>
          <el-dialog
          :title="userInfo.title"
          v-model="userInfo.userInfo"
          :destroy-on-close="true"
          :width="userInfo.width"
          :top="userInfo.top"
          @opened="show"
          :close-on-click-modal="false"
          @close="close(1)"
          center
        >
          <div class="userinforBox" v-if="!userInfo.editorBox && userInfo.userBox">
            <div class="info clearfix">
              <div class="title">
                <span>昵称: </span>
              </div>
              <span class="text" style="float: left">{{ userInfo.user.nikckName }}</span>
            </div>
            <div class="info clearfix">
              <div class="title">
                <span>QQ: </span>
              </div>
              <span class="text" style="float: left">{{ userInfo.user.qqNumber }}</span>
            </div>
            <div class="info clearfix">
              <div class="title">
                <span>微信: </span>
              </div>
              <span class="text" style="float: left">{{ userInfo.user.weChat }}</span>
            </div>
            <div class="info clearfix">
              <div class="title">
                <span>用户头像: </span>
              </div>
              <div class="userImg"><img :src="userInfo.user.userImg" alt="" /></div>
            </div>
          </div>
          <div class="editorBox" v-if="userInfo.editorBox">
            <el-form label-position="right" label-width="83px" class="editorForm" :model="data.formData">
              <el-form-item
                label="根贴ID："
                prop="parentPostId"
                :disabled="data.checkSuccess"
                :rules="[{ type: 'number', message: '根贴ID必须为数字值' }]"
              >
                <el-input
                  placeholder="若为回帖则填写根贴ID，自动校验"
                  type="parentPostId"
                  v-model.number="data.formData.parentPostId"
                  @blur="checkId(data.formData.parentPostId)"
                  :disabled="data.parent"
                >
                </el-input>
                <span v-if="data.checkSuccess"><i class="el-icon-circle-check success"></i></span>
                <span v-if="data.checkError"><i class="el-icon-circle-close error"></i></span>
              </el-form-item>
              <el-form-item label="标签：" v-if="data.reply">
                <el-select placeholder="请选择" v-model="data.formData.tagId">
                  <el-option
                    v-for="(item, index) in data.selectOption"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="标题：" prop="title" :rules="[{ min: 1, max: 26, message: '标题请不要超过26个字' }]">
                <el-input
                  placeholder="若填写根贴ID，此项不填"
                  type="title"
                  v-model="data.formData.title"
                  :disabled="data.checkSuccess || !data.reply"
                ></el-input>
              </el-form-item>
              <el-form-item label="类型：" v-if="data.reply">
                <el-select placeholder="请选择" v-model="data.formData.postType">
                  <el-option
                    v-for="(item, index) in data.selectOption4"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关联社区：" v-if="isNew.isNewPost">
                <el-button @click="openAddCommunity" :disabled="data.checkSuccess"
                  ><span style="margin-right: 6px">+</span>添加</el-button
                >
                <div class="communityBox">
                  <span v-if="data.communityIdLista">
                    {{ data.communityIdLista }}
                    <i
                      class="el-icon-close"
                      @click="delTag(index)"
                      style="margin-left: 10px"
                      v-if="!data.checkSuccess"
                    ></i>
                  </span>
                </div>
              </el-form-item>
              <el-form-item label="正文："> </el-form-item>
            </el-form>
            <input
              type="file"
              title=""
              name="uploads"
              id="uploads"
              accept="image/png, image/jpeg, image/jpg"
              multiple
              ref="dowloadImg"
              @change="uploadimg($event)"
              style='position: absolute;
              width: 54px;
              height: 42px;
              opacity: 0;
              z-index: 10011;
              '
            />
            <div id="div11"></div>
          </div>
          <div class="viewBox" v-if="userInfo.viewBox">
            <IframTem></IframTem>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button type="primary"
               size="small" @click="centerDialogVisible(1)" v-if='!userInfo.viewBox === true'
               :loading="data.upPost"
              >确 定</el-button>
            </span>
          </template>
        </el-dialog>
        </div>

        <el-dialog
          width="662px"
          title="关联社区添加"
          v-model="data.addCommunity"
          :destroy-on-close="true"
          :close-on-click-modal="false"
          @close="close(2)"
        >
          <div class="comForm">
            <el-form :inline="true">
              <el-form-item label="关联社区ID">
                <el-input placeholder="请输入" class="iniput" v-model="data.getComParmas.id"></el-input>
              </el-form-item>
              <el-form-item label="关联社区名称">
                <el-input placeholder="请输入" class="iniput" v-model="data.getComParmas.name"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="
                    width: 90px;
                    height: 36px;
                    padding: 0;
                    line-height: 36px;
                    min-height: 36px;
                    box-sizing: border-box;
                    margin-left: 6px;
                  "
                  @click="searchCom"
                  >检&nbsp;索</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="userInfoList">
            <el-table height="493" :data="data.communityTableList">
              <el-table-column prop="id" label="社区ID" align="center"> </el-table-column>
              <el-table-column prop="name" label="社区名称" align="center">
                <template v-slot="scope">
                  <div>
                    {{ scope.row.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="icon" label="社区ICON" align="center">
                <template v-slot="scope">
                  <div class="imgNameBox">
                    <div class="img">
                      <img class="table-td-thumb" :src="scope.row.icon" alt="缩略图" />
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template v-slot="scope">
                  <el-button @click="handleAdd(scope.row)" class="addComBtn">添加</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              style="float: right; padding-top: 10px"
              @size-change="communityHandleSizeChange"
              @current-change="communityHandleCurrentChange"
              :current-page="communityPage.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="communityPage.pageSize"
              layout="sizes, prev, pager, next"
              :total="data.communityTableTotal"
            >
            </el-pagination>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<!-- 调用   <mycom></mycom> -->
<script>
import { defineComponent, reactive, onMounted, ref, provide } from 'vue';
import { getPostList, addNewPost, editorPost, checkPost, upData, postManage, getUser } from '@/reactivity/post';
import { ElMessageBox, ElMessage } from 'element-plus';
import { getCommunityList } from '@/reactivity/user';
import E from 'wangeditor';
import { getImageSize } from '@/utils/common/index';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
import IframTem from '../common/IframTem';
export default defineComponent({
  components: {
    IframTem,
  },
  setup() {
    const store = useStore();
    // 定义请求数据
    const data = reactive({
      // 页面数据
      loading: true,
      tableList: [],
      tableTotal: 0,
      sendDate: [],
      communityIdLista: '',
      reply: true,
      needOpenId: 0,
      upPost: false,
      selectOption: [
        {
          label: '综合',
          value: 1,
        },
        {
          label: '问答',
          value: 2,
        },
        {
          label: '赛事',
          value: 3,
        },
        {
          label: '组队交友',
          value: 4,
        },
        {
          label: '活动',
          value: 5,
        },
        {
          label: '吐槽',
          value: 6,
        },
        {
          label: '攻略',
          value: 7,
        },
        {
          label: '问题反馈',
          value: 8,
        },
      ],
      selectOption5: [
        {
          label: '全部',
          value: 0,
        },
        {
          label: '综合',
          value: 1,
        },
        {
          label: '问答',
          value: 2,
        },
        {
          label: '赛事',
          value: 3,
        },
        {
          label: '组队交友',
          value: 4,
        },
        {
          label: '活动',
          value: 5,
        },
        {
          label: '吐槽',
          value: 6,
        },
        {
          label: '攻略',
          value: 7,
        },
        {
          label: '问题反馈',
          value: 8,
        },
      ],
      selectOption2: [
        {
          label: '全部',
          value: 0,
        },
        {
          label: '普通',
          value: 4,
        },
        {
          label: '精华',
          value: 2,
        },
        {
          label: '官方',
          value: 1,
        },
        {
          label: '精华&官方',
          value: 5,
        },
      ],
      selectOption3: [
        {
          label: '全部',
          value: '4,5',
        },
        {
          label: '通过',
          value: '4',
        },
        {
          label: '未通过',
          value: '5',
        },
      ],
      selectOption4: [
        {
          label: '普通',
          value: 4,
        },
        {
          label: '精华',
          value: 2,
        },
        {
          label: '官方',
          value: 1,
        },
        {
          label: '精华&官方',
          value: 5,
        },
      ],
      formData: {
        // 帖子表单数据
        postId: 0,
        title: '',
        content: '',
        tagId: 1,
        parentPostId: '',
        postCategory: 1,
        coverUrl: '',
        coverType: '',
        desc: '',
        communityId: 0,
        postType: 1,
        materialList: [],
      },
      searchInfo: {
        // 检索表单数据
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        creatorId: '', // 搜索条件发表者微信
        creatorId1: '', // 搜索条件发表者微信
        creatorId2: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        type: null, // 帖子类型
        beginTime: '', // 发表起始时间戳
        endTime: '', // 发表截止时间戳
        tagId: null, // 帖子标签
        parentPostId: null, // 主贴ID
        postStatus: '',
        status: '4,5', // 审核状态
        sendDate: [],
      },
      pageInfo: {
        pageNum: 1,
        pageSize: 5,
        status: '4,5',
      },
      checkSuccess: false,
      checkError: false,
      addCommunity: false,
      communityTableList: [],
      communityTableTotal: 0,
      getComParmas: {
        id: '',
        name: '',
        relateAppId: '',
        relateUser: '',
        status: '',
        createBeginTime: '',
        createEndTime: '',
        pageNum: '',
        pageSize: '',
      },
      parent: true,
      roleId: null,
    });
    provide('needIframeData', data);
    onMounted(async () => {
      const { roleId } = store.getters.userInfo;
      data.roleId = roleId;
      await getTableData(data.pageInfo);
    });

    // 定义增增帖子标识
    const isNew = reactive({
      isNewPost: true,
    });

    // 时间戳转化
    const timetrans = (val) => {
      let date = '';
      date = dayjs.unix(val).format('YYYY-MM-DD');
      return date;
    };

    // 页面加载请求数据
    const getTableData = async (req) => {
      try {
        const res = await getPostList(req);
        if (res.data.ret === 0) {
          data.loading = false;
          data.tableList = res.data.data.posts;
          data.tableTotal = res.data.data.pageInfo.total;
        } else {
          data.tableList = [];
          data.tableTotal = 0;
        }
      } catch (e) {
        console.log(e);
      }
    };

    // 定义标签处理方法
    const changeTag = (parms) => {
      let text = '';
      switch (parms) {
        case 0:
          text = '全部';
          break;
        case 1:
          text = '综合';
          break;
        case 2:
          text = '问答';
          break;
        case 3:
          text = '赛事';
          break;
        case 4:
          text = '组队交友';
          break;
        case 5:
          text = '活动';
          break;
        case 6:
          text = '吐槽';
          break;
        case 7:
          text = '攻略';
          break;
        case 8:
          text = '问题反馈';
          break;
        default:
          break;
      }
      return text;
    };

    // 定义状态处理方法
    const changeStaus = (parms) => {
      let text = '';
      switch (parms) {
        case 0:
          text = '全部';
          break;
        case 1:
          text = '机审中';
          break;
        case 2:
          text = '机审未通过';
          break;
        case 3:
          text = '未审核';
          break;
        case 4:
          text = '审核通过';
          break;
        case 5:
          text = '审核未通过';
          break;
      }
      return text;
    };

    // 翻页方法
    function handleSizeChange(val) {
      const searchData = {
        ...data.searchInfo,
        pageSize: val,
        pageNum: 1,
      };
      data.pageInfo.pageNum = 1;
      data.pageInfo.pageSize = val;
      getTableData(searchData);
    }
    function handleCurrentChange(val) {
      const searchData = {
        ...data.searchInfo,
        pageSize: data.pageInfo.pageSize,
        pageNum: val,
      };
      data.pageInfo.pageNum = val;
      console.log(data.pageInfo.pageNum);
      getTableData(searchData);
      // getTableData();
      // getPostList(val, 5);
    }

    // 获取时间戳
    const getTime = () => {
      console.log(data.searchInfo.sendDate);
      if (data.searchInfo.sendDate) {
        data.searchInfo.beginTime = dayjs(data.searchInfo.sendDate[0]).format('YYYY-MM-DD HH:mm:ss');
        data.searchInfo.endTime = dayjs(data.searchInfo.sendDate[1]).format('YYYY-MM-DD HH:mm:ss');
      } else {
        data.searchInfo.beginTime = '';
        data.searchInfo.endTime = '';
      }
    };

    // 顶级表单验证
    const rules = reactive({
      postId: [{ type: 'number', trigger: 'change', message: '帖子ID应为数字' }],
      creatorId: [{ type: 'number', trigger: 'change', message: 'QQ应为数字' }],
      creatorId1: [{ type: 'number', trigger: 'change', message: 'QQ应为数字' }],
      creatorId2: [{ type: 'any' }],
      parentPostId: [{ type: 'number', trigger: 'change', message: '主贴ID应为数字' }],
      createNickName: [{}],
      communityName: [{}],
      type: [{ type: 'any' }],
      tagId: [{ type: 'any' }],
      postStatus: [{}],
    });

    // 后去检索表单组件
    const searchFrom = ref(null);
    // 检索方法
    const getStatus = () => {
      if (data.searchInfo.postStatus !== '') {
        data.searchInfo.status = data.searchInfo.postStatus;
      }
      console.log(data.searchInfo.status, 'data.searchInfo.status');
    };
    const handleSearch = function () {
      searchFrom.value.validate((valid) => {
        if (data.searchInfo.creatorId1 && data.searchInfo.creatorId2) {
          ElMessage.warning({
            message: '请检查检索项',
          });
        }
        const searchData = {
          ...data.searchInfo,
          pageSize: data.pageInfo.pageSize,
          pageNum: data.pageInfo.pageNum,
        };
        if (valid) {
          getTableData(searchData);
        } else {
          ElMessage.warning({
            message: '请检查检索项',
          });
        }
      });
    };

    // 重置方法
    const clearSearchInfoFn = () => {
      // 重置按钮FN
      searchFrom.value.resetFields();
      data.searchInfo.sendDate = [];
      data.searchInfo.beginTime = '';
      data.searchInfo.endTime = '';
      data.searchInfo.status = '4,5';
      const searchData = {
        ...data.searchInfo,
        pageSize: 5,
        pageNum: 1,
      };
      data.pageInfo.pageSize = 5;
      data.pageInfo.pageNum = 1;
      getTableData(searchData);
    };

    // 打开用户信息弹框
    const userInfo = reactive({
      userInfo: false,
      editorBox: false,
      userBox: true,
      viewBox: false,
      title: '',
      width: '',
      top: '',
      hot: true,
      noHot: false,
      essence: false,
      user: {
        nikckName: '', // 用户昵称
        qqNumber: '', // 用户qq
        weChat: '', // 用户微信
        userImg: '', // 用户头像
      },
    });
    const openUserInfo = async (val) => {
      userInfo.userInfo = !userInfo.userInfo;
      userInfo.editorBox = false;
      userInfo.viewBox = false;
      userInfo.userBox = true;
      userInfo.title = '用户信息';
      userInfo.width = '400px';
      userInfo.top = '37vh';
      isNew.isNewPost = false;
      const sendData = {
        userId: val.creatorId,
      };
      try {
        const res = await getUser(sendData);
        userInfo.user.nikckName = res.data.data.nickName;
        userInfo.user.qqNumber = res.data.data.loginType === 1 ? res.data.data.userId : '/';
        userInfo.user.weChat = res.data.data.loginType === 2 ? res.data.data.userId : '/';
        userInfo.user.userImg = res.data.data.icon;
      } catch (e) {
        console.log(e);
      }
    };

    // 打开编辑框
    const handleEdit = async (val) => {
      isNew.isNewPost = false;
      userInfo.userInfo = !userInfo.userInfo;
      userInfo.editorBox = true;
      userInfo.viewBox = false;
      userInfo.width = '750px';
      userInfo.title = '帖子详情';
      userInfo.top = '7vh';
      const scopeData = {
        ...val,
      };
      data.formData.parentPostId = scopeData.parentPostId !== 0 ? scopeData.parentPostId : '';
      data.formData.postId = scopeData.id;
      data.formData.tagId = scopeData.tagId;
      data.formData.title = scopeData.title;
      data.formData.postType = 0;
      data.formData.content = scopeData.content;
      data.formData.communityId = scopeData.communityId;
      if (scopeData.isGood !== 0) {
        data.formData.postType = 2;
      }
      if (scopeData.isOfficial !== 0) {
        data.formData.postType = 1;
      }
      if (scopeData.isGood === 0 && scopeData.isOfficial === 0) {
        data.formData.postType = 4;
      }
      if (scopeData.isGood !== 0 && scopeData.isOfficial !== 0) {
        data.formData.postType = 5;
      }
      data.parent = true;
      console.log(scopeData);
      if (scopeData.parentPostId) {
        data.reply = false;
        data.formData.title = '';
      } else {
        data.reply = true;
      }
    };

    const anyTrue = ref(true);
    // 添加富文本
    let editor = reactive({});
    const show = () => {
      setTimeout(() => {
        editor = new E('#div11');
        editor.config.menus = [
          'image',
          // 'video',
          // 'link',
          'italic',
          'bold',
          'foreColor',
          'fontSize',
        ];
        editor.config.onchange = function (newHtml) {
          if (anyTrue.value) {
            anyTrue.value = false;
            if (newHtml.indexOf('<div class="img_wrap"><br/></div>') > -1) {
              const str = newHtml.replace('<div class="img_wrap"><br/></div>', '');
              console.log(str);
              editor.txt.html(str);
            }
          }
          setTimeout(() => {
            anyTrue.value = true;
          }, 500);
        };
        editor.config.onchangeTimeout = 10000;
        editor.create();
        if (isNew.isNewPost === false) {
          editor.txt.html(`<div>${data.formData.content}</div>`);
        } else {
          data.formData.content = editor.txt.html();
          data.formData.desc = editor.txt.text();
        }
      }, 50);
    };

    const dowloadImg = ref(null);
    const uploadimg = async (val) => {
      console.log(val);
      const file = val.target.files[0];
      const formData = new window.FormData();
      formData.append('file', file);
      let src = '';
      try {
        const { data } = await upData(formData);
        src = data.data.fileUrl;
        editor.txt.append(`<div class='img_wrap'><img class='add_img' src=${src} alt=''></div>`);
        if (data) {
          console.log(1);
          console.log(dowloadImg.value.value);
          dowloadImg.value.value = '';
        }
      } catch (e) {
        console.log(e);
      }
    };

    // 新建帖子
    const addNew = () => {
      userInfo.userInfo = !userInfo.userInfo;
      userInfo.editorBox = true;
      userInfo.viewBox = false;
      userInfo.width = '750px';
      userInfo.title = '帖子详情';
      userInfo.top = '7vh';
      data.formData.content = '';
      data.formData.title = '';
      data.formData.content = '';
      data.formData.tagId = 1;
      data.formData.postType = 1;
      data.formData.parentPostId = '';
      data.formData.postCategory = 0;
      data.formData.coverUrl = '';
      data.formData.coverType = '';
      data.formData.desc = '';
      data.formData.communityId = 0;
      isNew.isNewPost = true;
      data.reply = true;
      data.parent = false;
      data.formData.materialList = [];
      data.communityIdLista = '';
    };

    // 新帖发布提交方法
    const newPost = async (req) => {
      try {
        const res = await addNewPost(req);
        if (res.data.ret === 0) {
          data.upPost = false;
          ElMessage.success({
            message: '发布成功',
          });
          getTableData(data.pageInfo);
          userInfo.userInfo = !userInfo.userInfo;
        }
      } catch (e) {
        console.log(e);
      }
    };

    // 编辑发布提交方法
    const addEditorPost = async (req) => {
      try {
        const res = await editorPost(req);
        if (res.data.ret === 0) {
          data.upPost = false;
          ElMessage.success({
            message: '编辑成功',
          });
          getTableData(data.pageInfo);
        }
      } catch (e) {
        console.log(e);
      }
    };

    // 定义提取src方法
    const getSrc = async (dataContent) => {
      const re = new RegExp(/<img[^>]*>/gi); // 获取正文中img标签
      const url = new RegExp(/\bsrc\b\s*=\s*['"]?([^'"]*)['"]?/i); // 获取img标签中url地址
      if (dataContent.match(re) !== null) {
        // 正文中有没有图片
        const coverList = dataContent.match(re); // 正文中所有图片标签
        for (const src of coverList) {
          const item = {
            type: 0,
            width: 0,
            height: 0,
            url: '',
            videoId: '',
          };
          const imgUrl = src.match(url)[1];
          item.url = imgUrl;
          try {
            const { width, height } = await getImageSize(imgUrl);
            item.width = width;
            item.height = height;
            data.formData.materialList.push(item);
          } catch (e) {
            console.log(e);
          }
        }
      }
    };

    // 编辑提交功能
    const centerDialogVisible = async () => {
      if (!isNew.isNewPost) {
        data.upPost = true;
        data.formData.content = editor.txt.html();
        console.log(data.formData);
        if (data.formData.communityId === '') {
          delete data.formData.communityId;
        }
        if (data.formData.parentPostId === '') {
          delete data.formData.parentPostId;
        }
        await getSrc(data.formData.content);
        if (data.formData.parentPostId) {
          data.formData.postCategory = 2;
        } else {
          data.formData.postCategory = 1;
        }
        addEditorPost(data.formData);
        userInfo.userInfo = !userInfo.userInfo;
      } else if (isNew.isNewPost && !userInfo.viewBox) {
        data.upPost = true;
        data.formData.content = editor.txt.html();
        data.formData.desc = editor.txt.text();
        console.log(data.formData.content.indexOf('add_img'));
        if (data.formData.parentPostId === '') {
          delete data.formData.parentPostId;
        }
        await getSrc(data.formData.content);
        if (data.formData.parentPostId) {
          data.formData.postCategory = 2;
        } else {
          data.formData.postCategory = 1;
        }
        if (data.formData.communityId === '') {
          ElMessage.warning({
            message: '请选择关联社区',
          });
        } else if (data.formData.content === '') {
          ElMessage.warning({
            message: '请填写正文内容',
          });
        } else if (!data.formData.parentPostId && data.formData.title === '') {
          ElMessage.warning({
            message: '如不填写根贴ID，请填写标题',
          });
        } else if (data.checkSuccess === false && data.formData.title === '') {
          ElMessage.warning({
            message: '发布失败，请校验根贴ID',
          });
          data.checkSuccess = false;
        } else {
          newPost(data.formData);
        }
      } else if (userInfo.viewBox) {
        userInfo.userInfo = !userInfo.userInfo;
      }
    };

    // 删除方法
    const deleteRow = (index, rows) => {
      const sendData = {
        manageType: 0,
        postId: rows.id,
        reason: '',
      };
      ElMessageBox.confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          sendData.manageType = 7;
          try {
            const res = await postManage(sendData);
            console.log(res);
            if (res.data.ret === 0) {
              ElMessage.success({
                message: '删除成功',
              });
              getTableData(data.pageInfo);
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {
          ElMessage('取消删除');
        });
    };

    // 热门方法
    const handleHot = (val, row) => {
      const sendData = {
        manageType: 0,
        postId: row.id,
        reason: '',
      };
      if (row.parentPostId !== 0) {
        if (row.isGood === 0) {
          sendData.manageType = 3;
        } else {
          sendData.manageType = 4;
        }
      }
      if (row.parentPostId === 0) {
        if (row.isGood === 0) {
          sendData.manageType = 3;
        } else {
          sendData.manageType = 4;
        }
      }
      postManage(sendData).then((res) => {
        if (res.data.ret === 0) {
          ElMessage.success({
            message: '设置成功',
            type: 'success',
          });
          getTableData(data.pageInfo);
        }
      });
    };

    // 置顶方法
    const handleTop = (val, row) => {
      const sendData = {
        manageType: 0,
        postId: row.id,
        reason: '',
      };
      if (row.parentPostId === 0) {
        if (row.isTop === 0) {
          sendData.manageType = 1;
        } else {
          sendData.manageType = 2;
        }
      }
      postManage(sendData).then((res) => {
        if (res.data.ret === 0) {
          ElMessage.success({
            message: '设置成功',
            type: 'success',
          });
          getTableData(data.pageInfo);
        }
      });
    };

    // 打开预览方法
    const openView = (val) => {
      data.needOpenId = val.id;
      userInfo.userInfo = !userInfo.userInfo;
      userInfo.editorBox = false;
      userInfo.userBox = false;
      userInfo.viewBox = true;
      // userInfo.title = data.title;
      userInfo.top = '3vh';
      userInfo.width = '430px';
      /* setTimeout(() => {
        const iframe = document.getElementById('aaa');
        const deviceHeight = document.documentElement.clientHeight;
        iframe.style.height = `${Number(deviceHeight) - 220}px`; // 数字是页面布局高度差
        iframe.src = data.url + data.iframeId; // 数字是页面布局高度差
        console.log(iframe.src);
      }, 300);*/
    };

    // 校验根贴ID方法
    const checkId = (val) => {
      const sendData = {
        postId: val,
        userId: '',
      };
      if (val !== '') {
        checkPost(sendData).then((res) => {
          if (res.data.ret === 0) {
            data.checkSuccess = true;
            data.checkError = false;
            data.formData.title = '';
            data.formData.communityId = Number(res.data.data.communityInfo.communityId);
            data.communityIdLista = res.data.data.communityInfo.communityName;
          } else {
            ElMessage.error({
              message: '请检查你的根贴ID',
            });
            data.checkSuccess = false;
            data.checkError = true;
          }
        });
      } else {
        data.checkSuccess = false;
        data.checkError = false;
      }
    };

    // 对话框关闭事件
    const close = (val) => {
      data.checkSuccess = false;
      data.checkError = false;
      if (val === 1) {
        editor.destroy();
        editor = null;
      }
    };

    // 定义社区列表查询翻页
    const communityPage = reactive({
      pageNum: 1,
      pageSize: 5,
    });

    // 定义查询社区方法
    const getCommunity = async (req) => {
      try {
        const res = await getCommunityList(req);
        data.communityTableList = res.data.data.communities;
        data.communityTableTotal = res.data.data.pageInfo.total;
      } catch (e) {
        console.log(e);
      }
    };

    // 定义打开关联社区列表
    const openAddCommunity = () => {
      data.addCommunity = !data.addCommunity;
      getCommunity(communityPage);
    };

    // 定义检索社区发方法----------------------------------------------------------------
    const searchCom = () => {
      const sendData = {
        pageNum: communityPage.pageNum,
        pageSize: communityPage.pageSize,
        id: data.getComParmas.id,
        name: data.getComParmas.name,
      };
      getCommunityList(sendData).then((res) => {
        data.communityTableList = res.data.data.communities;
        data.communityTableTotal = res.data.data.pageInfo.total;
      });
    };

    // 翻页方法----------------------------------------------------------------
    function communityHandleSizeChange(val) {
      communityPage.pageNum = 1;
      communityPage.pageSize = val;
      getCommunity(communityPage);
    }
    function communityHandleCurrentChange(val) {
      communityPage.pageNum = val;
      getCommunity(communityPage);
    }

    // 定义添加社区方法----------------------------------------------------------------
    const handleAdd = (val) => {
      data.communityIdLista = val.name;
      data.formData.communityId = val.id;
    };

    // 定义删除标签的方法----------------------------------------------------------------
    const delTag = () => {
      data.communityIdLista = '';
      data.formData.communityId = '';
    };

    // 抽离成可配置的匹配列表
    const matchList  = {
      '&lt;': '<',
      '&gt;': '>',
      '&amp;': '&',
      '&#34;': '"',
      '&quot;': '"',
      '&#39;': '\'',
    };
    // 字符过滤器
    const HtmlFilter = (text) => {
      let regStr = `(${Object.keys(matchList).toString()})`;
      regStr = regStr.replace(/,/g, ')|(');
      const regExp = new RegExp(regStr, 'g');
      return text.replace(regExp, match => matchList[match]);
    };

    return {
      data,
      getTime,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      clearSearchInfoFn,
      userInfo,
      openUserInfo,
      handleEdit,
      show,
      deleteRow,
      handleHot,
      openView,
      centerDialogVisible,
      addNew,
      checkId,
      close,
      openAddCommunity,
      communityPage,
      changeTag,
      communityHandleSizeChange,
      communityHandleCurrentChange,
      searchCom,
      handleAdd,
      delTag,
      changeStaus,
      timetrans,
      handleTop,
      searchFrom,
      rules,
      isNew,
      getStatus,
      uploadimg,
      dowloadImg,
      HtmlFilter,
    };
  },
});
</script>

<style lang="scss">
@import './index.scss';
</style>
