
import { request } from '@/services';
import { AxiosRequestConfig } from 'axios';
import { Request } from '@/services/requests/request';
// 定义返回书数据通用格式
interface resultInterface<T> {
  ret: number;
  msg: string;
  data: T;
}

// 定义获取被管理用户列表参数接口
interface IgetUserParmas {
  userId: string;
  nickName: string;
  roleId: number;
  communityId: number;
  communityName: string;
  pageNum: number;
  pageSize: number;
}
// 定义获取被管理用户列表返回接口
interface IgetUsersRes {
  userDetailList: {
    userId: string;
    nickName: string;
    icon: string;
    roleId: number;
    creatorId: string;
    creatorNickName: string;
    createTime: number;
    totalPostSendNum: number;
    totalPostAuditNum: number;
    communityList: {
      id: number;
      name: string;
    }[];
    loginType: number;
    creatorIcon: string;
  }[];
  pageInfo: {
    pageNum: number;
    pageSize: number;
    total: number;
    totalPage: number;
  };
}

// 获取关联社区列表接口
interface IgetCommunity {
  id: number;
  name: string;
  relateAppId: number;
  relateUser: string;
  status: number;
  createBeginTime: number;
  createEndTime: number;
  pageNum: number;
  pageSize: number;
}
interface IgetCommunityList {
  communities: {
    id: number;
    name: string;
    relateAppId: number;
    relateUser: string;
    status: number;
    createBeginTime: number;
    createEndTime: number;
    icon: string;
    createTime: number;
    operatorId: number;
    operateTime: number;
    baseUserNum: number;
    baseHotNum: number;
    backgroundImg: string;
  }[];
  pageInfo: {
    pageNum: number;
    pageSize: number;
    total: number;
    totalPage: number;
  };
}


// 定义添加单个用户接口
interface IaddUser {
  userId: string;
  roleId: number;
  communityIdList: number[];
}
interface IaddUserReturn {
  ret: number;
  msg: string;
}

// 管理员添加某个用户请求
export function addUser(req: IaddUser): Promise<Request<AxiosRequestConfig, resultInterface<IaddUserReturn>>> {
  return request<AxiosRequestConfig, resultInterface<IaddUserReturn>>({
    url: '/api/manage/user/add_one',
    method: 'POST',
    data: req,
  });
}


// 编辑单个用户的信息请求参数接口
interface IeditUser {
  userId: string;
  roleId: number;
  communityList: number[];
}
interface IeditUserReturn {
  ret: number;
  msg: string;
}

// 获取管理端（B端）用户操作数据列表
interface IqueryOperation {
  userId: string;
  startTimeStamp: number;
  endTimeStamp: number;
  pageNum: number;
  pageSize: number;
}
interface IquerypOerationList {
  operationList: {
    dateStamp: number;
    sendPostNum: number;
    auditPostNum: number;
    auditCommentNum: number;
  }[];
}


// 管理员删除某个用户数据类型接口
interface IdelUser {
  userId: string;
}

// 获取用户列表(非管理员只能看到自己)
export function getSelf(): Promise<Request<AxiosRequestConfig, resultInterface<IgetUsersRes>>> {
  return request<AxiosRequestConfig, resultInterface<IgetUsersRes>>({
    url: '/api/manage/user/query_self_detail',
    method: 'get',
  });
}
// 获取用户列表
export function getManagementUserList(req: IgetUserParmas):
Promise<Request<AxiosRequestConfig, resultInterface<IgetUsersRes>>> {
  return request<AxiosRequestConfig, resultInterface<IgetUsersRes>>({
    url: '/api/manage/user/query_all_list',
    method: 'post',
    data: req,
  });
}

// 获取关联社区列表
export function getCommunityList(req: IgetCommunity):
Promise<Request<AxiosRequestConfig, resultInterface<IgetCommunityList>>> {
  return request<AxiosRequestConfig, resultInterface<IgetCommunityList>>({
    url: '/api/manage/community/batch',
    method: 'get',
    params: req,
  });
}


// 管理员编辑某个用户请求
export function editUser(req: IeditUser):
Promise<Request<AxiosRequestConfig, resultInterface<IeditUserReturn>>> {
  return request<AxiosRequestConfig, resultInterface<IeditUserReturn>>({
    url: '/api/manage/user/edit_one',
    method: 'POST',
    data: req,
  });
}


// 获取管理端（B端）用户操作数据列表
export function queryOperation(req: IqueryOperation):
Promise<Request<AxiosRequestConfig, resultInterface<IquerypOerationList>>> {
  return request<AxiosRequestConfig, resultInterface<IquerypOerationList>>({
    url: '/api/manage/user/query_operation_list',
    method: 'POST',
    data: req,
  });
}


// 管理员删除某个用户数据请求方法

export function delUser(req: IdelUser): Promise<Request<AxiosRequestConfig, resultInterface<null>>> {
  return request<AxiosRequestConfig, resultInterface<null>>({
    url: '/api/manage/user/delete_one',
    method: 'POST',
    data: req,
  });
}
